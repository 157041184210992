<template>
  <div class="container">
    <div class="pageTop">体检数据查询</div>
    <el-divider></el-divider>

    <div class="input-section">
      <el-input
          class="input-field"
          placeholder="请输入用户姓名或手机号"
          v-model="userNameOrPhone"
          clearable
          prefix-icon="el-icon-search"
      ></el-input>
      <el-button type="primary" @click="fetchUid" class="query-button">查询</el-button>
    </div>

    <!-- 显示查询结果 -->
    <div v-if="result !== null" class="button-section">
      <el-divider></el-divider>
      <el-button type="primary" @click="NewGetECG">查看心电记录</el-button>
      <el-button type="primary" @click="NewOtherFile" class="secondary-button">查看综合报告</el-button>
    </div>
    <div v-if="loading" class="loading-section">
      <el-divider></el-divider>
      <p>查询中，请稍候...</p>
    </div>
    <div v-if="error" class="error-section">
      <el-divider></el-divider>
      <p style="color: red;">查询失败，请检查输入或稍后再试。</p>
    </div>

    <div class="across-template-wrapper">
      <AcrossTemplate ref="GoAcross" />
    </div>
  </div>
</template>

<script>
import AcrossTemplate from "@/views/common/across_third.vue";
import Cookies from "js-cookie";

export default {
  components: {
    AcrossTemplate,
  },

  data() {
    return {
      userNameOrPhone: "", // 输入的用户名或手机号
      result: null, // 查询结果的状态
      loading: false, // 是否正在加载
      error: false, // 查询是否出错
    };
  },
  methods: {
    NewGetECG() {
      this.$refs.GoAcross.goECG();
    },
    NewOtherFile() {
      this.$refs.GoAcross.goOtherFile();
    },

    fetchUid() {
      if (!this.userNameOrPhone) {
        console.warn("请输入用户名或手机号！");
        return;
      }
      this.loading = true;
      this.error = false;

      this.$http({
        url: this.$http.adornUrl("/personal/user/app/getUid"),
        method: "post",
        params: this.$http.adornParams({
          userName: this.userNameOrPhone,
        }),
      })
          .then(({ data }) => {
            if (data && data.code === 0) {
              console.log("查询结果：", data);
              this.result = data;
              Cookies.set("PID", data.uid, { path: "/" });
            } else {
              console.error("查询失败，服务器错误");
              this.error = true;
            }
          })
          .catch((error) => {
            console.error("查询出错：", error);
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    adjustTemplateWidth() {
      const screenWidth = window.innerWidth;
      const template = this.$refs.GoAcross.$el; // 获取 AcrossTemplate DOM 节点
      if (screenWidth < 768) {
        template.style.width = `${screenWidth - 20}px`; // 动态设置宽度，留边距
      } else {
        template.style.width = "600px"; // 固定宽度
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.adjustTemplateWidth);
    this.adjustTemplateWidth();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.adjustTemplateWidth);
  },
};
</script>

<style scoped>
/* 通用容器样式 */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

/* 页面标题 */
.pageTop {
  font-size: 24px;
  margin-bottom: 20px;
}

/* 输入框和按钮区域 */
.input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

/* 输入框 */
.input-field {
  width: 90%;
  max-width: 500px;
  margin-bottom: 10px;
}

/* 按钮 */
.query-button,
.secondary-button {
  flex: 1; /* 按钮占据相同宽度 */
  max-width: 200px; /* 最大宽度 */
  text-align: center;
}

/* 操作按钮区域 */
.button-section {
  display: flex;
  flex-direction: row; /* 横向排列 */
  justify-content: center;
  align-items: center;
  gap: 10px; /* 按钮间距 */
  margin-top: 20px;
}

/* 加载提示区域 */
.loading-section,
.error-section {
  margin-top: 20px;
  text-align: center;
}

/* AcrossTemplate 容器样式 */
.across-template-wrapper {
  display: block;
  max-width: 100%; /* 限制最大宽度 */
  overflow-x: auto; /* 启用水平滚动 */
  margin: 0 auto; /* 居中对齐 */
}

/* 页中页固定宽度 */
.ecg-record {
  min-width: 600px; /* 固定宽度 */
  margin: 0 auto; /* 居中对齐 */
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .pageTop {
    font-size: 18px;
  }

  .input-field,
  .query-button,
  .secondary-button {
    width: 100%;
  }
}
</style>
