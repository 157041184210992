<!--  -->
<template>
  <div align="center" class='historypage'>
    <div v-if="isTreating">
      <div id="DomPdf"
        style="position: absolute;left: -9999px;top: -9999px;;width:794px;height:1123px;border:1px solid #000000;">
        <div style="margin:45px 57px">
          <el-row class="title">
            <el-col :span="6">
              <div class="blank"></div>
            </el-col>
            <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;"> 心电检测报告
              </span></el-col>
            <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                src="../../../../../public/img/logo_xingou.png" /></el-col>
          </el-row>
          <!-- 绘制双横线 -->
          <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
          <el-row class="info">
            <el-col :span="4"><span style="font-size: 14px"> 姓名：{{ realName }} </span></el-col>
            <el-col :span="3"><span style="font-size: 14px"> 性别：{{ gender }} </span></el-col>
            <el-col :span="3"><span style="font-size: 14px"> 年龄：{{ age }} </span></el-col>
            <el-col :span="8"><span style="font-size: 14px">
                时间：{{ createTime }}
              </span></el-col>
            <el-col :span="6"><el-tag style="font-size:16px"> 备注：{{ remark }} </el-tag> </el-col>
          </el-row>
          <!-- 绘制单横线 -->
          <hr style="border-top: 2px solid gray;" />
          <el-row class="info" style="height:30px">
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                PR间期：{{ conclusion.pr }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                QT间期：{{ conclusion.qt }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                QTc间期：{{ conclusion.qtc }}ms</span></el-col>
          </el-row>
          <el-row class="info" style="height:30px">
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                心率：{{ conclusion.xinlv }}bpm</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> P时限：
                {{ conclusion.p }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> QRS时限：
                {{ conclusion.qrs }}ms</span></el-col>
          </el-row>

          <div class="wapper">
            <!-- 网格  -->
            <svg id="svg" class="svg"></svg>
          </div>
          <!-- 疾病疑似 -->
          <div class="disease_content">
            <el-table :data="tableData" :header-cell-style="{ padding: '0px' }" :header-row-style="{ height: '30px' }"
              style="width: 100%;" :row-style="{ height: '24px' }" :cell-style="{ padding: '0px' }" border>
              <el-table-column type="index" label="NO." align="center" width="auto">
              </el-table-column>
              <el-table-column prop="items" label="检测项目" width="auto" align="center">
              </el-table-column>
              <el-table-column prop="suspectedDegrees" label="疑似度" width="auto" align="center">
              </el-table-column>
              <el-table-column prop="conclusion" label="结论" width="auto" align="center">
              </el-table-column>
            </el-table>

            <div style="text-align:right;font-size:14px">*提示:报告仅供参考，请结合临床情况</div>
            <br>
            <div style="text-align:right;font-size:14px">*模型信息: {{ conclusion.method }}</div>
          </div>
        </div>
      </div>

      <div id="pdf-viewer" style="width: 100%; border: 1px solid #000;"></div>
    </div>

    <div v-else>
      <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
        <div style="margin:45px 57px">
          <el-row class="title">
            <el-col :span="6">
              <div class="blank"></div>
            </el-col>
            <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;"> 心电检测报告
              </span></el-col>
            <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                src="../../../../../public/img/logo_xingou.png" /></el-col>
          </el-row>
          <!-- 绘制双横线 -->
          <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
          <el-row class="info">
            <el-col :span="4"><span style="font-size: 14px"> 姓名：{{ realName }} </span></el-col>
            <el-col :span="3"><span style="font-size: 14px"> 性别：{{ gender }} </span></el-col>
            <el-col :span="3"><span style="font-size: 14px"> 年龄：{{ age }} </span></el-col>
            <el-col :span="8"><span style="font-size: 14px">
                时间：{{ createTime }}
              </span></el-col>
            <el-col :span="6"><el-tag style="font-size:16px"> 备注：{{ remark }} </el-tag> </el-col>
          </el-row>
          <!-- 绘制单横线 -->
          <hr style="border-top: 2px solid gray;" />
          <el-row class="info" style="height:30px">
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                PR间期：{{ conclusion.pr }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                QT间期：{{ conclusion.qt }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                QTc间期：{{ conclusion.qtc }}ms</span></el-col>
          </el-row>
          <el-row class="info" style="height:30px">
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                心率：{{ conclusion.xinlv }}bpm</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> P时限：
                {{ conclusion.p }}ms</span></el-col>
            <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> QRS时限：
                {{ conclusion.qrs }}ms</span></el-col>
          </el-row>

          <div class="wapper">
            <!-- 网格  -->
            <svg id="svg" class="svg"></svg>
          </div>
          <!-- 疾病疑似 -->
          <div class="disease_content">
            <el-table :data="tableData" :header-cell-style="{ padding: '0px' }" :header-row-style="{ height: '30px' }"
              style="width: 100%;" :row-style="{ height: '24px' }" :cell-style="{ padding: '0px' }" border>
              <el-table-column type="index" label="NO." align="center" width="auto">
              </el-table-column>
              <el-table-column prop="items" label="检测项目" width="auto" align="center">
              </el-table-column>
              <el-table-column prop="suspectedDegrees" label="疑似度" width="auto" align="center">
              </el-table-column>
              <el-table-column prop="conclusion" label="结论" width="auto" align="center">
              </el-table-column>
            </el-table>

            <div style="text-align:right;font-size:14px">*提示:报告仅供参考，请结合临床情况</div>
            <br>
            <div style="text-align:right;font-size:14px">*模型信息: {{ conclusion.method }}</div>
          </div>
        </div>
      </div>
      <!-- <canvas id="ecg" width="793.7px" height="1122.52px" style="border:black 1px solid">您的浏览器不支持canvas</canvas><br> -->
      <!-- <iframe id="pdf-viewer" style="width: 100%; height: 600px; border: 1px solid #000; margin-top: 20px;"></iframe> -->

      <el-button type="primary" @click="showMarkPoint()">查看标记点</el-button>
      <el-button type="primary" plain @click="generatePDF()">下载PDF</el-button>
      <!--            <el-button type="primary" plain @click="generateAndEmbedPdf()">预览PDF</el-button>-->
      <el-dialog title="pdf预览" :visible.sync="dialogVisible" width="100%" :before-close="handleClose">
        <div id="pdf-viewer" style="width: 100%; border: 1px solid #000;"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// Import the jsPDF and PDF.js libraries
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import Cookies from "js-cookie";
import 'svg2pdf.js'
import 'jspdf-autotable';

// 配置PDF.js工作者路径
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export default {
  // import引入的组件需要注入到对象中才能使用

  // props传递属性（从checkContent中来）
  props: {
    userId: Number,  // 【其他平台】读取的属性
    recordId: String, // 【其他平台】读取的属性
    createTimeId: String, // 【其他平台】读取的属性
    durationId: Number, //【其他平台】读取的属性
  },
  components: {},
  data() {
    //这里存放数据
    return {
      isTreating: Cookies.get('Treating') == 99, //2的时候显示预览版，现在不要，所以干脆置成99
      uid: this.userId,  // 从props中赋值
      sid: this.recordId, // 从props中赋值
      createTime: this.createTimeId,  // 从props中赋值
      duration: this.durationId, // 从props中赋值
      htmlTitle: 'PDF_ECG',
      tableData: [{ items: "窦性心动过速", suspectedDegrees: "", conclusion: "" },
      { items: "窦性心动过缓", suspectedDegrees: "", conclusion: "" },
      { items: "窦性心律不齐", suspectedDegrees: "", conclusion: "" },
      // { items: "窦性停搏", suspectedDegrees: "", conclusion: "" },
      { items: "房性早搏", suspectedDegrees: "", conclusion: "" },
      { items: "交界性早搏", suspectedDegrees: "", conclusion: "" },
      { items: "室性早搏", suspectedDegrees: "", conclusion: "" },
      { items: "室上性心动过速", suspectedDegrees: "", conclusion: "" },
      { items: "室性心动过速", suspectedDegrees: "", conclusion: "" },
      { items: "交界性逸搏", suspectedDegrees: "", conclusion: "" },
      { items: "室性逸搏", suspectedDegrees: "", conclusion: "" },
      { items: "房颤", suspectedDegrees: "", conclusion: "" },
      { items: "心梗相关疾病", suspectedDegrees: "", conclusion: "" }],
      ecgData: [],
      conclusion: {
        pr: 12,
        date: '',
        qt: '',
        qtc: '',
        p: '',
        qrs: '',
        xinlv: '',
        method: '规则模型',
      },
      lastrecord: '',
      gender: '',
      age: '',
      realName: '',
      endTime: '',
      MarkPointFlag: false,
      remark: '',
      dialogVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },
  },
  //监控data中的数据变化
  watch: {
    //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据


  },
  //方法集合
  methods: {
    async loadFont() {
      try {
        const font = await import('../../../../utils/STSONG-normal.js');
        this.font = font.default;
        console.log('Font loaded');
        this.fontloaded = true;
        console.log(this.fontloaded);
      } catch (error) {
        console.error('Failed to load font:', error);
      }
    },
    async generatePDF() {
      if (!this.fontloaded) {
        this.$message({
          message: "正在下载中，请稍等.",
          type: "warning",
        });
        await this.loadFont();

      }
      const doc = new JsPDF();

      doc.setFont('STSONG');
      // const element = document.getElementById('DomPdf');
      const svgElement = document.getElementById('svg');
      var width = svgElement.clientWidth;
      var height = svgElement.clientHeight;

      // 设置 viewBox 属性
      svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
      if (!svgElement) {
        console.error('SVG element not found');
        return;
      }
      console.log(svgElement);
      doc.setFont('STSONG');

      doc
        .svg(svgElement, {
          x: 15,
          y: 45,
          width: 180,
          height: 140,
          useCSS: true // 确保使用CSS样式
        })
        .then(() => {
          doc.setFontSize(20);
          doc.text('心电检测报告', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });


          doc.setFontSize(12);
          doc.text(`姓名：${this.realName}`, 20, 30);
          doc.text(`性别：${this.gender}`, 55, 30);
          doc.text(`年龄：${this.age}`, 80, 30);
          doc.text(`时间：${this.createTime}`, 105, 30);
          doc.text(`备注：${this.remark}`, 165, 30);

          doc.text(`PR间期：${this.conclusion.pr}ms`, 20, 40);
          doc.text(`QT间期：${this.conclusion.qt}ms`, 90, 40);
          doc.text(`QTc间期：${this.conclusion.qtc}ms`, 160, 40);
          doc.text(`P时限：${this.conclusion.p}ms`, 20, 45);
          doc.text(`QRS时限：${this.conclusion.qrs}ms`, 90, 45);
          doc.text(`心率：${this.conclusion.xinlv}bpm`, 160, 45);
          // Add SVG image to PDF

          const tableColumn = ["检测项目", "疑似度", "结论"];
          const tableRows = this.tableData.map(row => [
            row.items,
            row.suspectedDegrees,
            row.conclusion
          ]);

          doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 180,
            styles: {
              font: 'STSONG',
            },
            headStyles: {
              fillColor: [255, 255, 255], // 将表头背景颜色
              textColor: [0, 0, 0], // 将表头文本颜色
              fontStyle: 'bold', // 设置表头文本为加粗
              fontSize: 12,
            },
          });
          doc.setFontSize(8);
          doc.text('*提示:报告仅供参考，请结合临床情况', 140, 285);
          doc.text('*模型信息: ' + this.conclusion.method, 140, 290);
          doc.save('报告.pdf');
        })


    },
    generateAndEmbedPdf() {
      this.dialogVisible = true;
      const element = document.getElementById("DomPdf");
      html2Canvas(element, {
        allowTaint: true,
        useCORS: true,
        scale: 1.5
      }).then((canvas) => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        const pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        const imgWidth = 595.28;
        const imgHeight = 592.28 / contentWidth * contentHeight;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new JsPDF('', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        const pdfBlob = pdf.output('blob');
        const url = URL.createObjectURL(pdfBlob);

        // 使用 PDF.js 加载和显示 PDF
        const loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(pdf => {
          pdf.getPage(1).then(page => {
            const dialogWidth = window.innerWidth; // 使用窗口宽度
            const viewport = page.getViewport({ scale: 1 });
            const scale = dialogWidth / viewport.width * 0.9;
            const scaledViewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: scaledViewport // 使用缩放后的视口
            };
            page.render(renderContext).promise.then(() => {
              const pdfViewer = document.getElementById('pdf-viewer');
              pdfViewer.innerHTML = ''; // 清除之前的内容
              pdfViewer.appendChild(canvas);
            });
          });
        });
      });
    },

    // async generateAndEmbedPdf() {
    //   this.dialogVisible = true;
    //   const element = document.getElementById("DomPdf");
    //   const pdf = new JsPDF();
    //
    //   // 获取DOM元素的文本内容
    //   const textContent = element.innerText || element.textContent;
    //
    //   // 将文本内容按行分割
    //   const lines = textContent.split('\n');
    //
    //   // 设置初始位置和行高
    //   let yPosition = 10;
    //   const lineHeight = 10;
    //
    //   // 遍历每一行并将其添加到PDF中
    //   lines.forEach(line => {
    //     pdf.text(line, 10, yPosition);
    //     yPosition += lineHeight;
    //     // 如果Y轴位置超过页面高度，则添加新页面
    //     if (yPosition > pdf.internal.pageSize.getHeight()) {
    //       pdf.addPage();
    //       yPosition = 10;
    //     }
    //   });
    //
    //   const pdfBlob = pdf.output('blob');
    //   const url = URL.createObjectURL(pdfBlob);
    //
    //   // 使用 PDF.js 加载和显示 PDF
    //   const loadingTask = pdfjsLib.getDocument(url);
    //   loadingTask.promise.then(pdf => {
    //     pdf.getPage(1).then(page => {
    //       const dialogWidth = window.innerWidth; // 使用窗口宽度
    //       const viewport = page.getViewport({ scale: 1 });
    //       const scale = dialogWidth / viewport.width * 0.9;
    //       const scaledViewport = page.getViewport({ scale });
    //       const canvas = document.createElement('canvas');
    //       const context = canvas.getContext('2d');
    //       canvas.height = scaledViewport.height;
    //       canvas.width = scaledViewport.width;
    //
    //       const renderContext = {
    //         canvasContext: context,
    //         viewport: scaledViewport // 使用缩放后的视口
    //       };
    //       page.render(renderContext).promise.then(() => {
    //         const pdfViewer = document.getElementById('pdf-viewer');
    //         pdfViewer.innerHTML = ''; // 清除之前的内容
    //         pdfViewer.appendChild(canvas);
    //       });
    //     });
    //   });
    // },

    // 计算结束时间
    async EndTime() {
      var startTime = +new Date(this.createTime.substring(0, 4),
        this.createTime.substring(5, 7) - 1,
        this.createTime.substring(8, 10),
        this.createTime.substring(11, 13),
        this.createTime.substring(14, 16),
        this.createTime.substring(17, 19));
      var endTime = new Date(startTime + this.duration * 1000)
      let y = endTime.getFullYear();
      let MM = endTime.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = endTime.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = endTime.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = endTime.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = endTime.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
      this.endTime = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒
    },


    drawECG() {
      // this. init_Canvas();
      var svg_grid = this.$Snap('#svg');
      // 图层零点（1,1）
      // 注意图层顺序，先画小的，再画大的
      // 绘制小方格
      for (let j = 1; j < 180; j++) {
        svg_grid.line(0, 3.75 * j + 1, 675, 3.75 * j + 1).attr({
          stroke: "#e03433",
          strokeWidth: 0.3,
        });
        svg_grid.line(3.75 * j + 1, 0, 3.75 * j + 1, 675).attr({
          stroke: "#e03433",
          strokeWidth: 0.3,
        });
      }
      // 绘制大方格
      for (let i = 0; i < 37; i++) {
        svg_grid.line(0, 18.75 * i + 1, 675, 18.75 * i + 1).attr({
          stroke: "#e03433",
          strokeWidth: 1.2,
        });
        svg_grid.line(18.75 * i + 1, 0, 18.75 * i + 1, 675).attr({
          stroke: "#e03433",
          strokeWidth: 1.2,
        });
      }
      var t1 = svg_grid.paper.line(20, 21, 41, 21).attr({
        stroke: "#000",
        strokeWidth: 5
      });
      // 注释
      var t2 = svg_grid.paper.text(15, 16, "5mm").attr({ "font-family": "STSONG" });
      var t3 = svg_grid.paper.text(100, 25, "时间:" + this.conclusion.date).attr({ "font-family": "STSONG" });
      var t5 = svg_grid.paper.text(400, 25, "增益：10mm/mV").attr({ "font-family": "STSONG" });
      var t6 = svg_grid.paper.text(550, 25, "走速：25mm/s").attr({ "font-family": "STSONG" });
      var t7 = svg_grid.paper.text(480, 485, "时间" + this.endTime).attr({ "font-family": "STSONG" });
      // 标志
      for (let y = 0; y <= 400; y += 18.75 * 8) {
        var p1 = svg_grid.paper
          .polyline(
            2,
            113.5 + y,
            6,
            113.5 + y,
            6,
            76 + y,
            15,
            76 + y,
            15,
            113.5 + y,
            19,
            113.5 + y
          )
          .attr({
            fill: "none",
            stroke: "black",
            strokeWidth: 2,
          });
      }
      // 绘制心电图
      // 心电图坐标零点x0 = 34px，y0 = 68+17*i，一页画10秒，一秒5大格，一大格5小格，一小格3.75px
      // 8导联采样频率500Hz，一秒500个点，一大格100个点，一小格20个点，点与点之间相邻0.1875px
      var ecgData1 = this.ecgData.slice(0, 3430);
      var ecgData2 = this.ecgData.slice(3430, 3430 * 2);
      var ecgData3 = this.ecgData.slice(3430 * 2, this.ecgData.length + 1);
      var yValue1 = ecgData1.map(function (index) {
        return 113.5 - (index * 0.0360608 / 320000) * 18.75 * 2;          //* 0.0360608 / 320000表示mV信号的转换，* 18.75*8表示计算的增益
      });
      var yValue2 = ecgData2.map(function (index) {
        return 113.5 + 18.75 * 8 - (index * 0.0360608 / 320000) * 18.75 * 2;
      });
      var yValue3 = ecgData3.map(function (index) {
        return 113.5 + 18.75 * 16 - (index * 0.0360608 / 320000) * 18.75 * 2;
      });
      var len1 = yValue1.length;
      var len2 = yValue2.length;
      var len3 = yValue3.length;
      for (let k = 0; k < len1; k++) {
        yValue1.splice(2 * k, 0, 19 + 0.1875 * k);
      }
      for (let k = 0; k < len2; k++) {
        yValue2.splice(2 * k, 0, 19 + 0.1875 * k);
      }
      for (let k = 0; k < len3; k++) {
        yValue3.splice(2 * k, 0, 19 + 0.1875 * k);
      }
      var ecg1 = svg_grid.paper.polyline(yValue1).attr({
        fill: "none",
        stroke: "black",
        strokeWidth: 1,
      });
      var ecg2 = svg_grid.paper.polyline(yValue2).attr({
        fill: "none",
        stroke: "black",
        strokeWidth: 1,
      });
      var ecg3 = svg_grid.paper.polyline(yValue3).attr({
        fill: "none",
        stroke: "black",
        strokeWidth: 1,
      });
      // this.generateAndEmbedPdf();

    },
    drawMarkPoint() {

      var svg_grid = this.$Snap('#svg')

      // 标记点绘制 q r s s2 p t
      var colorarray = ["Yellow", "LimeGreen", "DeepSkyBlue", "SandyBrown", "DeepPink", "BlueViolet"] // 色谱
      this.MK = []
      for (let i = 0; i < 6; i++) {
        var MarkPoint = "";
        if (i == 0) {
          MarkPoint = this.Qlist
        }
        else if (i == 1) {
          MarkPoint = this.Rlist
        }
        else if (i == 2) {
          MarkPoint = this.Slist
        }
        else if (i == 3) {
          MarkPoint = this.S2list
        }
        else if (i == 4) {
          MarkPoint = this.Plist
        }
        else {
          MarkPoint = this.Tlist
        }
        var R = MarkPoint.map(function (index) {
          return 19 + 0.1875 * index;
        });

        for (let k = 0; k < MarkPoint.length; k++) {
          if (MarkPoint[k] == 0)        // 去掉标记点为0的点
          {
            continue
          }
          else if (MarkPoint[k] < 3430) {
            this.MK.push(svg_grid.paper.circle(R[k], 113.5 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
              fill: "none",
              stroke: colorarray[i],
              strokeWidth: 1.5,
            }));

          }
          else if (MarkPoint[k] >= 3430 && MarkPoint[k] < 6860) {
            this.MK.push(svg_grid.paper.circle(R[k] - 3430 * 0.1875, 113.5 + 18.75 * 8 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
              fill: "none",
              stroke: colorarray[i],
              strokeWidth: 1.5,
            }));
          }
          else {
            this.MK.push(svg_grid.paper.circle(R[k] - 3430 * 0.1875 * 2, 113.5 + 18.75 * 16 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
              fill: "none",
              stroke: colorarray[i],
              strokeWidth: 1.5,
            }));
          }
        }

      }
      console.log(this.MK[0])
    },
    async getData() {
      await this.$http.adornHeader(this.header),
        // 根据每条心电记录的sid获取该条的数据和结论
        this.$http({
          url: this.$http.adornUrl('/personal/staticecg/getEcgData'),
          method: 'get',
          params: this.$http.adornParams({ sid: this.sid })
        }).then(({ data }) => {
          if (data && data.code === 0) {

            this.ecgData = data.ecgData
            // console.log("看看数据")
            // console.log(this.ecgData)
            this.ecgData = this.ecgData.split(",")
            this.drawECG();
            this.MarkPointFlag = true   //【查看标记点的开关】
            this.showMarkPoint();
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg)
          }
        })
      await this.$http({
        url: this.$http.adornUrl('/personal/staticecg/getConclusion'),
        method: 'get',
        params: this.$http.adornParams({ sid: this.sid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          console.log(data)
          this.conclusion_random = data.conclusionData
          this.conclusion = JSON.parse(this.conclusion_random)   //JSON格式转换
          // this.conclusion.rlist = this.conclusion.rlist.shift()
          console.log(this.conclusion)
          if (this.conclusion.method != 0) {
            this.conclusion.method = '深度学习'
          }
          else {
            this.conclusion.method = '规则模型'
          }
          this.Rlist = this.conclusion.rlist.split(",")
          this.Qlist = this.conclusion.qlist.split(",")
          this.Slist = this.conclusion.slist.split(",")
          this.S2list = this.conclusion.s2list.split(",")
          this.Plist = this.conclusion.plist.split(",")
          this.Tlist = this.conclusion.tlist.split(",")
          var Disease = [this.conclusion.xdgs, this.conclusion.xdgh, this.conclusion.xlbq, this.conclusion.fxzb,
          this.conclusion.jjxzb, this.conclusion.sxzb, this.conclusion.ssxxdgs, this.conclusion.sxxdgs,
          this.conclusion.fxyb, this.conclusion.sxyb, this.conclusion.fc, this.conclusion.qtyc]
          // 疑似度
          for (let k = 0; k < 11; k++) {
            this.tableData[k].suspectedDegrees = Disease[k] + "%"
          }
          this.tableData[11].suspectedDegrees = '-' //心梗没有疑似度
          // 结论
          for (let i = 0; i < 11; i++) //除了心梗相关疾病的结论
          {
            this.tableData[i].conclusion = '正常'
            if (Disease[i] > 50) {
              this.tableData[i].conclusion = '偏高'
            }
          }

          this.tableData[11].conclusion = this.conclusion.con13 //心梗相关疾病的结论
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
      await this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          this.age = data.age
          this.gender = data.gender
          var genderMap = { 0: "女", 1: "男" }
          this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })

    },
    showMarkPoint() {
      this.MarkPointFlag = !this.MarkPointFlag
      // console.log(this.MarkPointFlag)
      if (this.MarkPointFlag == true) {
        this.drawMarkPoint()
      }
      else {
        if (this.MK != undefined) {
          for (let i = 0; i < this.MK.length; i++)
            this.MK[i].remove()
        }
      }
    },

    async TreatJudge() {
      if (this.isTreating) {
        await this.getData();
        await this.EndTime();
        await this.generateAndEmbedPdf();
      } else {
        // 你原有的其他初始化逻辑
        this.getData();
        this.EndTime();
      }
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.crossPlatform != null && this.$route.query.crossPlatform != undefined) {
      // 小程序端请求
      this.crossPlatformStatus = true
    }
    // if (this.$store.state.module.crossPlatform) {
    //   console.log('yes')
    //   this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
    // }
    this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
    // this.sid='500'
    console.log(this.$store.state.personal_module.uid);
    if (this.$route.query.staticRecordId != null && this.$route.query.staticRecordId != undefined) {
      this.uid = this.$route.query.uid
      this.sid = this.$route.query.staticRecordId
      this.createTime = this.$route.query.staticRecordCreatetime
      this.duration = this.$route.query.staticRecordDuration
      this.remark = this.$route.query.remark

      // 执行页面显示
      this.TreatJudge()

    } else {
      this.uid = this.$route.query.uid
      // 异步请求获取数据
      this.$http({
        url: this.$http.adornUrl("/personal/clouddoctor/app/getLatestECGRecord"),
        method: "post",
        params: this.$http.adornParams({ uid: this.uid }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.lastrecord = data

          this.sid = this.lastrecord.sid
          this.createTime = this.lastrecord.create_time
          this.duration = this.lastrecord.duration

          // 执行页面显示
          this.TreatJudge()

        } else {
          console.log("看看报错");
          this.$message.error(data.msg);
        }
      });
    }

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getData();
    // this.EndTime();
    // Generate and embed PDF after data is loaded
    // this.drawECG();

    // this.TreatJudge()
  },
  beforeCreate() {

  }, //生命周期 - 创建之前
  beforeMount() {

  }, //生命周期 - 挂载之前
  beforeUpdate() {

  }, //生命周期 - 更新之前
  updated() {

  }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}

.wapper {
  position: relative;
  height: 500px;
}

.svg {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 500px;
}
</style>
