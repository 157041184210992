<template>
  <div class="container">
    <div class="pageTop">体检数据查询</div>
    <el-divider></el-divider>

    <div class="input-section">
      <el-input
          class="input-field"
          placeholder="请输入用户姓名或手机号"
          v-model="userNameOrPhone"
          clearable
          prefix-icon="el-icon-search"
      ></el-input>
      <el-button type="primary" @click="fetchUid" class="query-button">查询</el-button>
    </div>

    <!-- 显示查询结果 -->
    <div v-if="result !== null" class="button-section">
      <el-divider></el-divider>
      <el-button type="primary" @click="NewGetECG">查看心电记录</el-button>
      <el-button type="primary" @click="NewOtherFile" class="secondary-button">查看综合报告</el-button>
    </div>
    <div v-if="loading" class="loading-section">
      <el-divider></el-divider>
      <p>查询中，请稍候...</p>
    </div>
    <div v-if="error" class="error-section">
      <el-divider></el-divider>
      <p style="color: red;">查询失败，请检查输入或稍后再试。</p>
    </div>

    <AcrossTemplate ref="GoAcross"></AcrossTemplate>
  </div>
</template>

<script>
import AcrossTemplate from "@/views/common/across_third.vue";
import Cookies from "js-cookie";

export default {
  components: {
    AcrossTemplate,
  },

  data() {
    return {
      userNameOrPhone: "", // 输入的用户名或手机号
      result: null, // 查询结果的状态
      loading: false, // 是否正在加载
      error: false, // 查询是否出错
    };
  },
  methods: {
    NewGetECG() {
      this.$refs.GoAcross.goECG();
    },
    NewOtherFile() {
      this.$refs.GoAcross.goOtherFile();
    },

    fetchUid() {
      if (!this.userNameOrPhone) {
        console.warn("请输入用户名或手机号！");
        return;
      }
      this.loading = true;
      this.error = false;

      this.$http({
        url: this.$http.adornUrl('/personal/user/app/getUid'),
        method: 'post',
        params: this.$http.adornParams({
          userName: this.userNameOrPhone,
        }),
      })
          .then(({ data }) => {
            if (data && data.code === 0) {
              console.log("查询结果：", data);
              this.result = data;
              Cookies.set("PID", data.uid, { path: "/" });
            } else {
              console.error("查询失败，服务器错误");
              this.error = true;
            }
          })
          .catch((error) => {
            console.error("查询出错：", error);
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
/* 通用容器样式 */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

/* 页面标题 */
.pageTop {
  font-size: 24px;
  margin-bottom: 20px;
}

/* 输入框和按钮区域 */
.input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

/* 输入框 */
.input-field {
  width: 90%;
  max-width: 500px;
  margin-bottom: 10px;
}

/* 按钮 */
.query-button {
  width: 90%;
  max-width: 500px;
}

/* 操作按钮区域 */
.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

/* 次级按钮 */
.secondary-button {
  margin-top: 10px;
  width: 90%;
  max-width: 500px;
}

/* 加载提示区域 */
.loading-section,
.error-section {
  margin-top: 20px;
  text-align: center;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .pageTop {
    font-size: 18px;
  }

  .input-field,
  .query-button,
  .secondary-button {
    width: 100%;
  }
}
</style>
