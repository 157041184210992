<!--  -->
<template>
  <div align="center" class='holter-form'>
    <div id="DomPdf" style="width:100%;height:auto;">
      <div v-if="this.$store.state.module.moduleName != 'older_station'&&this.isnotTreating &&this.$store.state.module.moduleName != 'home_older'">
        <div>
                    <span style="display: inline-block; font-size: 24px">
                        心狗健康监测报告单
                    </span>
          <img style="width: 100px; vertical-align: middle; display: inline-block"
               src="../../../../public/img/logo_xingou.png" />
        </div>
        <div>
          时间：{{ formerday }}至{{ today }}
        </div>
        <el-row style="border:solid 2px;">
          <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">个人信息
          </div>
          <el-descriptions border :labelStyle="infoStyle" :contentStyle="contentStyle">
            <el-descriptions-item v-for="(item, key) in info" :key="item" :label="dict[key]">
              {{ item }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions border :labelStyle="infoStyle">
            <el-descriptions-item label="健康自述">
              {{ HealthSelfReport }}
            </el-descriptions-item>
          </el-descriptions>
        </el-row>


        <el-row>
          <el-col :span="15"
                  style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                1.心电 <span style="font-size: 14px;">{{ ecg.date }}</span></div>
            </el-row>
            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in ecg" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item > 50 ? '偏高' + '(' + item + '%)' : '正常' }}
              </el-descriptions-item>
            </el-descriptions>
            <!--            <el-row style="border:none">-->
            <!--              <div-->
            <!--                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">-->
            <!--                2.尿常规 <span style="font-size: 14px;">{{ urine.date }}</span></div>-->
            <!--            </el-row>-->
            <!--            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">-->
            <!--              <el-descriptions-item v-for="(item, key) in urine" v-if="key != 'date'" :key="item"-->
            <!--                                    :label="dict[key]">-->
            <!--                {{ item }}-->
            <!--              </el-descriptions-item>-->
            <!--            </el-descriptions>-->
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                2.体脂 <span style="font-size: 14px;">{{ bodyFat.date }}</span></div>
            </el-row>
            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in bodyFat" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>

          </el-col>
          <el-col :span="9" style="border:solid 1px;border-top: solid 2px; border-bottom:solid 1px">
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                3.血压 <span style="font-size: 14px;">{{ bloodPressure.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle">
              <el-descriptions-item v-for="(item, key) in bloodPressure" v-if="key != 'date'" :key="item"
                                    :label="dict[key]" class="description-item">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>

            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                4.血氧 <span style="font-size: 14px;">{{ bloodOxygen.spDate }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in bloodOxygen" v-if="key != 'spDate'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                5.血糖 <span style="font-size: 14px;">{{ bloodSugar.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in bloodSugar" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>
            <!--            <el-row style="border:none">-->
            <!--              <div-->
            <!--                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">-->
            <!--                7.体温 <span style="font-size: 14px;">{{ temperature.date }}</span></div>-->
            <!--            </el-row>-->
            <!--            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">-->
            <!--              <el-descriptions-item v-for="(item, key) in temperature" v-if="key != 'date'" :key="item"-->
            <!--                                    :label="dict[key]">-->
            <!--                {{ item }}-->
            <!--              </el-descriptions-item>-->
            <!--            </el-descriptions>-->

            <!--            <el-row style="border:none">-->
            <!--              <div-->
            <!--                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">-->
            <!--                8.血脂 <span style="font-size: 14px;">{{ bloodLipids.date }}</span></div>-->
            <!--            </el-row>-->
            <!--            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">-->
            <!--              <el-descriptions-item v-for="(item, key) in bloodLipids" v-if="key != 'date'" :key="item"-->
            <!--                                    :label="dict[key]">-->
            <!--                {{ item }}-->
            <!--              </el-descriptions-item>-->
            <!--            </el-descriptions>-->
          </el-col>
        </el-row>
        <!--        <el-row-->
        <!--            style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">-->
        <!--          <el-row style="border:none">-->
        <!--            <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">-->
        <!--              9.三合一 <span style="font-size: 14px;">{{ threeInOne.date }}</span></div>-->
        <!--          </el-row>-->
        <!--          <el-descriptions :column="1" border :labelStyle="labelStyle">-->
        <!--            <el-descriptions-item v-for="(item, key) in threeInOne" v-if="key != 'date'" :key="item"-->
        <!--                                  :label="dict[key]">-->
        <!--              {{ item }}-->
        <!--            </el-descriptions-item>-->
        <!--          </el-descriptions>-->
        <!--        </el-row>-->
        <el-row
            style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
          <el-row style="border:none">
            <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
              6.综合结论 </div>
          </el-row>
          <el-descriptions :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item label="结论">
              {{ this.zonghe }}
            </el-descriptions-item>
          </el-descriptions>
        </el-row>
        <div style="text-align:right;font-size:14px">*提示:报告仅供参考，请结合临床情况</div>
        <br>

      </div>
      <div v-if="this.$store.state.module.moduleName == 'older_station'||this.isTreating ||this.$store.state.module.moduleName == 'home_older'">
        <div>
                    <span style="display: inline-block; font-size: 24px">
                        心狗健康监测报告单
                    </span>
          <img style="width: 100px; vertical-align: middle; display: inline-block"
               src="../../../../public/img/logo_xingou.png" />
        </div>
        <div>
          时间：{{ formerday }}至{{ today }}
        </div>
        <el-row style="border:solid 2px;">
          <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">个人信息
          </div>
          <el-descriptions border :labelStyle="infoStyle" :contentStyle="contentStyle">
            <el-descriptions-item v-for="(item, key) in info" :key="item" :label="dict[key]">
              {{ item }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions border :labelStyle="infoStyle">
            <el-descriptions-item label="健康自述">
              {{ HealthSelfReport }}
            </el-descriptions-item>
          </el-descriptions>
        </el-row>


        <el-row>
          <el-col :span="15"
                  style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                心电 <span style="font-size: 14px;">{{ ecg.date }}</span></div>
            </el-row>
            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in ecg" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item > 50 ? '偏高' + '(' + item + '%)' : '正常' }}
              </el-descriptions-item>
            </el-descriptions>
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                尿常规 <span style="font-size: 14px;">{{ urine.date }}</span></div>
            </el-row>
            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in urine" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>
            <!-- <el-row style="border:none">
                <div
                    style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                    3.体脂 <span style="font-size: 14px;">{{ bodyFat.date }}</span></div>
            </el-row>
            <el-descriptions :column="2" border :labelStyle="labelStyle" :contentStyle="contentStyle">
                <el-descriptions-item v-for="(item, key) in bodyFat" v-if="key != 'date'" :key="item"
                    :label="dict[key]">
                    {{ item }}
                </el-descriptions-item>
            </el-descriptions> -->

          </el-col>
          <el-col :span="9" style="border:solid 1px;border-top: solid 2px; border-bottom:solid 1px">
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                血压 <span style="font-size: 14px;">{{ bloodPressure.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle">
              <el-descriptions-item v-for="(item, key) in bloodPressure" v-if="key != 'date'" :key="item"
                                    :label="dict[key]" class="description-item">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>

            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                血氧 <span style="font-size: 14px;">{{ bloodOxygen.spDate }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in bloodOxygen" v-if="key != 'spDate'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                体温 <span style="font-size: 14px;">{{ temperature.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item v-for="(item, key) in temperature" v-if="key != 'date'" :key="item"
                                    :label="dict[key]">
                {{ item }}
              </el-descriptions-item>
            </el-descriptions>
            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                血糖 <span style="font-size: 14px;">{{ threeInOne.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item :label=dict.bloodsugar>
                {{ threeInOne.bloodsugar }}
              </el-descriptions-item>
            </el-descriptions>

            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                尿酸 <span style="font-size: 14px;">{{ threeInOne.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item :label=dict.uricAcid>
                {{ threeInOne.uricAcid }}
              </el-descriptions-item>
            </el-descriptions>

            <el-row style="border:none">
              <div
                  style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                总胆固醇 <span style="font-size: 14px;">{{ threeInOne.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
              <el-descriptions-item :label=dict.cholesterol>
                {{ threeInOne.cholesterol }}
              </el-descriptions-item>
            </el-descriptions>
            <!--
            <el-row style="border:none">
                <div
                    style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                    8.血脂 <span style="font-size: 14px;">{{ bloodLipids.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle" :contentStyle="contentStyle">
                <el-descriptions-item v-for="(item, key) in bloodLipids" v-if="key != 'date'" :key="item"
                    :label="dict[key]">
                    {{ item }}
                </el-descriptions-item>
            </el-descriptions> -->
          </el-col>
        </el-row>
        <el-row
            style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
          <el-row style="border:none">
            <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
              综合结论 </div>
          </el-row>
          <el-descriptions :column="1" border :labelStyle="labelStyle">
            <el-descriptions-item label="结论">
              {{ this.zonghe }}
            </el-descriptions-item>
          </el-descriptions>
        </el-row>
        <div style="text-align:right;font-size:14px">*提示:报告仅供参考，请结合临床情况</div>
        <br>
        <!-- <el-row
            style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
            <el-row style="border:none">
                <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">
                    9.三合一 <span style="font-size: 14px;">{{ threeInOne.date }}</span></div>
            </el-row>
            <el-descriptions :column="1" border :labelStyle="labelStyle">
                <el-descriptions-item v-for="(item, key) in threeInOne" v-if="key != 'date'" :key="item"
                    :label="dict[key]">
                    {{ item }}
                </el-descriptions-item>
            </el-descriptions>
        </el-row> -->
      </div>
    </div>

    <el-button type="primary" plain @click="getPdf('#DomPdf')">下载监测报告</el-button>
    <div style="padding-top:3px">注：使用微信查看时，点击右上角从浏览器打开页面下载</div>


  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Cookies from "js-cookie";

const dict = { realName: '姓名' }

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    userId: String,  // 【其他平台】读取的属性
  },
  data() {
    //这里存放数据
    return {
      isTreating: Cookies.get('Treating') == 2,
      isnotTreating: Cookies.get('Treating') != 2,
      did: 0,
      uid: this.userId,
      recordTime: "",
      today: "",
      formerday: "",
      HealthSelfReport: "",
      zonghe:"",
      dict: // key的字典
          {
            realName: '姓名',
            age: '年龄',
            gender: '性别',
            height: '身高',
            HealthSelfReport: '健康自述',
            xdgs: '窦性心动过速',
            xdgh: '窦性心动过缓',
            sxyb: '室性逸博',
            qtyc: '心梗相关疾病',
            fxzb: '房性早搏',
            fxyb: '交界性逸博',
            xlbq: '窦性心律不齐',
            sxzb: '室性早搏',
            sxxdgs: '室性心动过速',
            fc: '心房颤动',
            ssxxdgs: '室上性心动过速',
            highpressure: '高压 90-139',
            lowpressure: '低压 60-89',
            xinlv: '心率',
            disease: '疾病',
            leu: '白细胞(LEU)',
            nit: '亚硝酸盐(NIT)',
            ubg: '尿胆原(UBG)',
            pro: '蛋白质(PRO)',
            ph: '酸碱度(pH)',
            sg: '尿比重(SG)',
            ca: '钙离子(Ca)',
            bld: '潜血(BLD)',
            ket: '酮体(KET)',
            bil: '胆红素(BIL)',
            glu: '葡萄糖(GLU)',
            vc: '维生素C(VC)',
            ma: '微量白蛋白(MA)',
            cr: '肌酐(Cr)',
            // suggest: '建议',
            spo2Value: '血氧(%) ＞94%',
            value: '血糖值(mmol/L) 3.9-7.8',
            tc: '总胆固醇(mmol/L)',
            hdlC: '高密度脂蛋白胆固醇(mmol/L)',
            ldlC: '低密度脂蛋白胆固醇(mmol/L)',
            triglyceride: '甘油三酯(mmol/L)',
            bloodsugar: '血糖(mmol/L)',
            uricAcid: '尿酸(umol/L)',
            cholesterol: '胆固醇(mmol/L)',
            temp: '温度(℃)',
            bmi: 'BMI 18.5-23.9',
            weight: '体重(kg)',
            bodyfatrate: '体脂率(%)',
            bodywaterrate: '体水分(%)',
            kcal: '基础代谢量(kcal)',
            fatrate: '皮下脂肪率(%)',
            fattygrade: '内脏脂肪等级',
            bonesrate: '骨骼肌率(%)',
            boneweight: '骨量(kg)',
            protein: '蛋白质(%)',
            feature: '特性'
          },
      info:
          {
            realName: ' ',
            age: ' ',
            gender: ' ',
            height: ' ',
            weight: ' ',
          },
      ecg: {
        date: ' ',
        xdgs: ' ',
        xdgh: ' ',
        sxyb: ' ',
        qtyc: ' ',
        fxzb: ' ',
        fxyb: ' ',
        xlbq: ' ',
        sxzb: ' ',
        sxxdgs: ' ',
        fc: ' ',
        ssxxdgs: ' ',
      },
      bloodPressure: {
        date: ' ',
        highpressure: ' ',
        lowpressure: ' ',
      },
      urine: {
        date: ' ',
        leu: "",
        nit: "",
        ubg: "",
        pro: "",
        ph: "",
        sg: "",
        ca: "",
        bld: "",
        ket: "",
        bil: "",
        glu: "",
        vc: "",
        ma: "",
        cr: "",
        // suggest: "",
        feature: ""
      },
      bloodOxygen: {
        spDate: " ",
        spo2Value: "",
        feature: "",
      },

      bloodSugar: {
        date: '',
        value: "",
        // suggest: "",
        feature: "",
        disease: "",
      },
      bloodLipids: {
        date: '',
        tc: "",
        hdlC: "",
        ldlC: "",
        triglyceride: "",
        // suggest: "",
        feature: "",
      },
      threeInOne: {
        date: '',
        bloodsugar: '',
        uricAcid: "",
        cholesterol: '',
        feature: "",
        // suggest: ""
      },
      temperature: {
        date: '',
        temp: "",
      },
      bodyFat: {
        date: '',
        bmi: "",
        weight: "",
        bodyfatrate: "",
        bodywaterrate: "",
        kcal: "",
        fatrate: "",
        fattygrade: "",
        bonesrate: "",
        boneweight: "",
        protein: "",
        // suggest: "",
        feature: ""
      },
      htmlTitle: '心狗健康监测报告单',
      labelStyle: {
        'width': '25%',
        'color': '#606266'
      },
      infoStyle: {
        'width': '10%',
        'color': '#606266'
      },
      contentStyle: {
        'width': '25%',
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    initCanvas() {
      console.log("初始化canvas")
      let canvas = document.getElementById('report');
      let can = canvas.getContext('2d');
      can.font = " 40px 微软雅黑";
      can.textAlign = "start";
      can.fillText("心狗健康监测报告单", 420, 40);
      can.moveTo(10, 70);
      can.lineTo(1200, 70);
      can.moveTo(10, 80);
      can.lineTo(1200, 80);
      can.stroke();
      can.beginPath();
      can.font = "28px 仿宋";
      can.textAlign = "start";
      can.fillText("姓名:   ", 80, 125);
      can.fillText("性别:   ", 300, 125);
      can.fillText("年龄:   ", 520, 125);
      can.fillText("身高:   ", 720, 125);
      can.fillText("体重:   ", 940, 125);
      // can.moveTo(0/scale,150/scale);
      // can.lineTo(1350/scale,150/scale);
      can.stroke();
    },
    getRecentRecord() {
      if (this.$store.state.module.crossPlatform) {
        console.log("这是跨平台");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.info.realName = data.info.realName
          this.info.age = data.info.age
          var genderMap = { 0: "女", 1: "男" }
          this.info.gender = genderMap[data.info.gender]
          // this.info[0]["value"] = data.realName
          // this.info[2]["value"] = data.age

          // var genderMap = { 0: "女", 1: "男" }
          // this.info[1]["value"] = genderMap[data.gender]
          this.info.height = data.info.height
          this.info.weight = data.info.weight
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })



      this.$http({
        url: this.$http.adornUrl('/personal/clouddoctor/app/getRecentRecord'),
        method: 'post',
        params: this.$http.adornParams({
          uid: this.uid,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0 && data.msg === "success") {

          console.log("看看数据")
          console.log(data)
          let con = data.zonghe
          console.log(con)
          if(con["flag"] === 1){
            this.zonghe = "近期检测项目存在异常，请咨询医生！异常项目："
            let keys_name = Object.keys(con)
            console.log(keys_name)
            for(let i in Object.keys(con)){
              let key = keys_name[i]
              if(key != "flag") this.zonghe = this.zonghe + key + ","
            }
            this.zonghe = this.zonghe.slice(0,-1)
          }else{
            this.zonghe = "近期检测正常！"
          }

          this.HealthSelfReport = data.itemsRecord.healthSelfReport
          // 获取所有键，然后遍历键，获取键对应的值，再将值设置为新的值
          if (data.itemsRecord.ecg != null) {
            Object.keys(this.ecg).forEach(key => {
              let value = data.itemsRecord.ecg[key];
              this.ecg[key] = value;
            });
          }
          if (data.itemsRecord.bloodPressure != null) {
            Object.keys(this.bloodPressure).forEach(key => {
              let value = data.itemsRecord.bloodPressure[key];
              this.bloodPressure[key] = value;
            });
          }

          if (data.itemsRecord.urine != null) {
            Object.keys(this.urine).forEach(key => {
              let value = data.itemsRecord.urine[key];
              this.urine[key] = value;
            });
          }

          if (data.itemsRecord.bloodOxygen != null) {
            Object.keys(this.bloodOxygen).forEach(key => {
              let value = data.itemsRecord.bloodOxygen[key];
              this.bloodOxygen[key] = value;
            });
          }

          if (data.itemsRecord.bloodSugar != null) {
            Object.keys(this.bloodSugar).forEach(key => {
              let value = data.itemsRecord.bloodSugar[key];
              this.bloodSugar[key] = value;
            });
          }


          if (data.itemsRecord.bloodLipids != null) {
            Object.keys(this.bloodLipids).forEach(key => {
              let value = data.itemsRecord.bloodLipids[key];
              this.bloodLipids[key] = value;
            });
          }

          if (data.itemsRecord.threeInOne != null) {
            Object.keys(this.threeInOne).forEach(key => {
              let value = data.itemsRecord.threeInOne[key];
              this.threeInOne[key] = value;
            });
          }


          if (data.itemsRecord.temperature != null) {
            Object.keys(this.temperature).forEach(key => {
              let value = data.itemsRecord.temperature[key];
              this.temperature[key] = value;
            });
          }

          if (data.itemsRecord.bodyFat != null) {
            Object.keys(this.bodyFat).forEach(key => {
              let value = data.itemsRecord.bodyFat[key];
              this.bodyFat[key] = value;
            });

          }


        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    getToday() {
      var today = new Date();
      var formerday = new Date(today);
      formerday.setDate(today.getDate() - 7);
      this.year_today = today.getFullYear()
      this.year_formerday = formerday.getFullYear()
      this.today = this.TimeProcess(today)
      this.formerday = this.TimeProcess(formerday)
      console.log(this.today)
      console.log(this.formerday)
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$store.state.module.moduleName === 'personal_module') {
      this.uid = this.$store.state.personal_module.uid
    }
    if (this.$route.query.crossPlatform != null && this.$route.query.crossPlatform != undefined) {
      // 小程序端请求
      this.crossPlatformStatus = true
    }
    if (this.$store.state.module.crossPlatform) {
      console.log('yes')
      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
    }
    if (this.$route.query.uid != null && this.$route.query.uid != undefined) {
      this.uid = this.$route.query.uid
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.initCanvas()
    this.getToday()
    this.getRecentRecord()

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style>
.rowclearfix {
  display: flex;
  justify-content: center;
  gap: 10px
}

.tablecss {
  border: 4px solid gray
}

.canvas {
  display: block;
  margin: 0 auto;
}

.el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 1px 2px;
}
</style>