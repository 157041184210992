<!--  -->
<template>
  <div class=''>
    <div style="font-size: 28px;">我的成员管理</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-input style="width: 500px" placeholder="请输入用户姓名查询" v-model="search" clearable>
    </el-input>
    <!-- <div align='right' class="indicate_legend">
      <span class="blank" style="background-color:#F6BEB8 ">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏高</span>
      <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style="background-color:rgb(234 215 79) ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏低</span>

    </div> -->
    <el-table :data="finalTableData.filter(data => !search || data.uname.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" border :header-cell-style="{ 'text-align': 'center' }" :cell-style="cellStyle" v-loading="loadingShow">

      <el-table-column type="index" width="40"></el-table-column>


      <el-table-column label="用户姓名" prop="uname" width="80">
        <!-- <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.uname">
            </el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button @click="updateRealName(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template> -->
      </el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <!-- <el-table-column label="个人信息"  width="150">
          <template slot-scope="scope">
            <el-button @click="psInfo(scope.row)" type="primary">{{ '个人信息表' }}</el-button>
          </template>
</el-table-column> -->
      <el-table-column label="基础信息">
        <!-- <el-table-column label="性别" prop="gender">
        </el-table-column> -->
        <el-table-column label="孕期" prop="pregnancy">
        </el-table-column>
        <el-table-column label="年龄" prop="age">
        </el-table-column>
        <el-table-column label="身高" prop="height">
        </el-table-column>
        <el-table-column label="体重" prop="weight">
        </el-table-column>
        <!-- <el-table-column label="位置" prop="addr">
        </el-table-column> -->
      </el-table-column>
      <el-table-column prop="userInfo" label="个人信息表">
        <template slot-scope="scope">
          <el-button @click="psInfo(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="体检报告">
        <template slot-scope="scope">
          <el-button @click="ExamReport(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="体温单" v-if="this.$store.state.module.moduleName == 'older_station'">
        <template slot-scope="scope">
          <el-button @click="GetSanCeDan(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="健康信息" width="100">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <!-- <el-button @click="getECG(scope3.row.uid)">
                静态心电
              </el-button>
              <el-button @click="getECGHolter(scope3.row.uid)">
                动态心电
              </el-button> -->
            <!-- <el-button @click="get8ECGHolter(scope3.row.uid)">
                8导联
              </el-button> -->
            <el-button @click="getTem(scope3.row.uid)">
              妇温宝
            </el-button>
            <el-button @click="getBaby(scope3.row.uid)">
              胎心仪
            </el-button>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <el-button @click="getBloodPressure(scope3.row.uid)">
              血压
            </el-button>
            <el-button @click="getBloodOxygen(scope3.row.uid)">
              血氧
            </el-button>
            <el-button @click="getBodyTemprature(scope3.row.uid)">
              体温
            </el-button>
            <el-button @click="getThreeInOne(scope3.row.uid)">
              三合一
            </el-button>
            <el-button @click="getBodyFat(scope3.row.uid)">
              体脂
            </el-button>
            <el-button @click="getUrine(scope3.row.uid)">
              尿检
            </el-button>
            <!-- <el-button @click="get8ECG(scope3.row.uid)">
              8导联静态
            </el-button>
            <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联holter
            </el-button> -->
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- <el-table-column label="关联检测">
        <el-table-column label="体重(kg)" prop="weight" width="80"> </el-table-column>
        <el-table-column label="血压(mmHg)" prop="pressure" width="80"> </el-table-column>
        <el-table-column label="血糖(mmol/L)" prop="sugar" width="80">
        </el-table-column>
        <el-table-column label="血氧(%)" prop="oxygen" width="80"> </el-table-column>
        <el-table-column label="尿检" prop="urine" width="80">
          <template v-slot="scope">
            <el-tag :type="scope.row.urine === '正常' ? 'success' : 'warning'" disable-transitions
              style="font-size: 14px;">{{ scope.row.urine }}
            </el-tag>
          </template>
        </el-table-column>

      </el-table-column> -->

      <!-- <el-table-column label="备注">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.remarks">
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column> -->

      <el-table-column label="导出">
        <template slot-scope="scope">
          <el-button @click="GetMonthData(scope.row.uid, scope.row.uname, scope.row.gender, scope.row.age)"
            type="success" plain>{{ '导出' }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope">
          <!-- <el-link type="primary" @click="psInfo(scope.row)">{{ '个人信息表' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span> -->
          <el-link type="primary" @click="">{{ '上报' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-button type="danger" @click="handleDelete(scope.$index, scope.row)" size="mini"><i
              class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- <el-dialog :visible.sync="psInfoVisible">
        <ps_information/>
      </el-dialog> -->
    <div style="padding-top: 40px">
      <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
      <el-col :span="6">
        <el-input :rows="1" placeholder="请输入用户名或手机号" v-model="userNameOrPhone">
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button @click="addNewUser" type="primary">添加</el-button>
      </el-col>
    </div>
    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
    <div v-if="ExamReportVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="ExamReportVisible">
        <exam_report :userId="this.uidCurrent" />
      </el-dialog>
    </div>

    <div v-if="SanCeDanVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="SanCeDanVisible" width="80%">
        <SanCeDan :userId="this.uidCurrent" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'
import exam_report from '../../personal_module/records/report.vue'
import SanCeDan from '../../personal_module/records/SanCeDan/chartView.vue'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information,
    exam_report,
    SanCeDan,
  },
  data() {
    //这里存放数据
    return {
      loadingShow: true,
      size: '',
      uidCurrent: '',
      tableData: [
        // {
        //   uid: '1',
        //   realName: '田圆圆',
        //   phone: '13244567890',
        //   weight: 68,
        //   pressure: '120/90',
        //   sugar: 2,
        //   oxygen: 98,
        //   urine: '正常',
        //   pregnancy: '18周',
        // },
        // {
        //   uid: '2',
        //   realName: '张异凡',
        //   phone: '18023457332',
        //   weight: 70,
        //   pressure: '112/76',
        //   sugar: 4,
        //   oxygen: 99,
        //   urine: '异常',
        //   pregnancy: '20周',

        // },
        // {
        //   uid: '157',
        //   realName: '刘金磊',
        //   phone: '13598856743',
        //   weight: 77,
        //   pressure: '130/88',
        //   sugar: 14.1,
        //   oxygen: 98,
        //   urine: '正常',
        //   pregnancy: '30周',

        // },
      ],
      finalTableData: [],
      userNameOrPhone: '',
      search: '',
      psInfoVisible: false,
      ExamReportVisible: false,
      OlderReportVisible: false,
      SanCeDanVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }

  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    handleDelete(index, row) {
      console.log("看看删除啥", index, row);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/delUserRelation"),
          method: "get",
          params: this.$http.adornParams({
            eid: this.$store.state.women_baby.eid,
            uid: row.uid,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });

            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              type: 'info',
              message: data.msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 查询怀孕时间

     getPregnancyInfo(pregnant_time) {
      let pregnant_week='';
      if (pregnant_time == null) {
        pregnant_week = "未怀孕"
      } else {
        pregnant_week = this.calculateWeekNumber(pregnant_time) + "周"
      }
      return pregnant_week
    },
     getAllPregnancyInfo()  {
      for (let i = 0; i < this.tableData.length; i++) {
        let pregnant_time = this.tableData[i].pregnant_time;
        let pregnancyContent = this.getPregnancyInfo(pregnant_time);
        console.log('hereeeee')
        // 将查询到的孕期内容合并到对应的data_info行数据中
        this.tableData[i].pregnancy = pregnancyContent;
      }
      this.finalTableData = this.tableData;
      this.loadingShow = false
    },
    calculateWeekNumber(dateStr) {
      let givenDate = new Date(dateStr);
      console.log("看看怀孕时间2", givenDate);
      let currentDate = new Date();

      let timeDiff = currentDate - givenDate;
      let daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      let weekNumber = Math.floor(daysDiff / 7);

      return weekNumber;
    },

    // 获取小屋成员List
    getUserList() {
      this.$http({
        url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/ListUserAll"),
        method: "get",
        params: this.$http.adornParams({
          eid: this.$store.state.women_baby.eid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据list数据");
          console.log(data);
          this.tableData = data.user_info
          this.getAllPregnancyInfo()

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    // 检测项目跳转
    getECG(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: uid,
        }
      })
    },
    getECGHolter(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        }
      })
    },

    getBloodFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyGlucose(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyGlucoseJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodOxygen(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodPressure(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        }
      })
    },

    getBio(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBioJump',
        query: {
          uid: uid,
        }
      })
    },

    getBodyFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: uid,
        }
      })
    },
    getThreeInOne(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryThreeInOneJump',
        query: {
          uid: uid,
        }
      })
    },
    getUrine(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryUrineJump',
        query: {
          uid: uid,
        }
      })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
    getTem(e) {
      console.log('看看uid传过来了没', e)
      let uid = e
      this.$router.push({
        name: 'HistoryTemJump',
        query: {
          uid: uid,
        }
      })
    },
    getBaby(e) {
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBabyJump',
        query: {
          uid: uid,
        }
      })
    },

    // 导出当月的健康信息
    groupDataByDate(data, type) {
      return data.reduce((acc, item) => {
        const date = item.date.split(' ')[0];
        if (!acc[date] || new Date(item.date) > new Date(acc[date].date)) {
          if (type === 'temp') {
            acc[date] = { date: item.date, temp: item.temp };
          } else if (type === 'bp') {
            acc[date] = { date: item.date, highpressure: item.highpressure, lowpressure: item.lowpressure, xinlv: item.xinlv };
          }
        }
        return acc;
      }, {});
    },
    async GetTempAndBP(Uid) {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1; // getMonth() 返回 0-11，所以需要 +1
      this.startTime = `${year}-${String(month).padStart(2, '0')}-01`;
      // 获取当前月份的最后一天
      const endOfMonth = new Date(year, month, 0);
      this.endTime = `${year}-${String(month).padStart(2, '0')}-${String(endOfMonth.getDate()).padStart(2, '0')}`;
      console.log("当月时间格式")
      console.log(this.startTime)
      console.log(this.endTime)

      await this.$http({
        url: this.$http.adornUrl("/personal/personaltemperature/listByTime"),
        method: "post",
        data: this.$http.adornData({ uid: Uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          this.TempData = data.personalTemperatureEntities;
          console.log("看看体温数据");
          console.log(this.TempData);
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      this.$http.adornHeader(this.header)
      await this.$http({
        url: this.$http.adornUrl("/personal/personalbloodpressure/listByTime"),
        method: "get",
        params: this.$http.adornParams({ uid: Uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          this.BPData = data.personalBloodPressureEntities;
          console.log("看看血压数据");
          console.log(this.BPData);
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    async GetMonthData(Uid, uname, gender, age) {
      await this.GetTempAndBP(Uid)
      console.log("等了吗")
      this.DownloadData = []
      const tempDataMap = this.groupDataByDate(this.TempData, 'temp');
      const bpDataMap = this.groupDataByDate(this.BPData, 'bp');
      let currentDate = new Date(this.startTime);
      const endDate = new Date(this.endTime);
      while (currentDate <= endDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        this.DownloadData.push({
          date: dateStr,
          temp: tempDataMap[dateStr] ? tempDataMap[dateStr].temp : '',
          highpressure: bpDataMap[dateStr] ? bpDataMap[dateStr].highpressure : '',
          lowpressure: bpDataMap[dateStr] ? bpDataMap[dateStr].lowpressure : '',
          xinlv: bpDataMap[dateStr] ? bpDataMap[dateStr].xinlv : ''
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      console.log("看看整合数据")
      console.log(this.DownloadData)
      this.download_table(uname, gender, age)

    },
    download_table(uname,gender,age) {
      // Create a new array with updated key names
      const excel_total = this.DownloadData.map(item => {
        const newItem = { ...item }; // Clone the item to avoid modifying the original data
        console.log('download')
        console.log(newItem)
        newItem['时间'] = newItem.date;
        newItem['体温(℃)'] = newItem.temp;
        newItem['高压(mmHg)'] = newItem.highpressure;
        newItem['低压(mmHg)'] = newItem.lowpressure;
        newItem['心率(次/分)'] = newItem.xinlv;


        delete newItem.date;
        delete newItem.temp;
        delete newItem.highpressure;
        delete newItem.lowpressure;
        delete newItem.xinlv;


        return newItem;
      });
      const columnNames = ['时间', '体温(℃)', '高压(mmHg)', '低压(mmHg)', '心率(次/分)'];
      const wb = XLSX.utils.book_new();
      const worksheetData = [
        [`姓名：${uname}`, `性别：${gender}`,`年龄：${age}`],
        columnNames,
        ...excel_total.map(item => Object.values(item))
      ];

      // Create a new worksheet from the worksheetData
      const ws1 = XLSX.utils.aoa_to_sheet(worksheetData);
      // const ws1 = XLSX.utils.json_to_sheet(excel_total);
      XLSX.utils.book_append_sheet(wb, ws1, "健康信息");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      // Save the workbook
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '健康信息统计.xlsx');

    },

    // 添加新用户
    addNewUser() {
      if (this.addUid == "") {
        this.$alert('未填写用户信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        console.log("看看eId", this.$store.state.women_baby.eid, this.userNameOrPhone);
        console.log(typeof (this.$store.state.women_baby.eid));
        let that = this
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/addUserRelationPhoneOrName"),
          method: "get",
          params: this.$http.adornParams({
            eid: this.$store.state.women_baby.eid,
            phone: that.userNameOrPhone,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.userNameOrPhone = ""
            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    // updateRealName(e) {
    //   this.$http({
    //     url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateUserRealName"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       uid: e.uid,
    //       realName: e.realName
    //     }),
    //   }).then(({ data }) => {
    //     if (data && data.code === 0) {
    //       console.log("看看数据2023年1月9日");
    //       console.log(data);
    //     } else {
    //       console.log(data);
    //       this.$message({
    //         message: data.msg,
    //         type: "warning",
    //       });
    //     }
    //   });
    // },
    updateRemark(e) {
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateRemarks"),
        method: "post",
        data: this.$http.adornData({
          reid: e.reid,
          remarks: e.remarks
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid

    },
    ExamReport(e) {
      this.ExamReportVisible = true
      this.uidCurrent = e.uid
    },

    GetSanCeDan(e) {
      this.SanCeDanVisible = true
      this.uidCurrent = e.uid
      console.log(this.uidCurrent)

    },
    cellStyle({ row, column, rowIndex, columnIndex }) {

      if (columnIndex == 7) {
        if (row.sugar > 6.1) {
          return 'background: #F6BEB8 ';
        }
        if (row.sugar < 3.9) {
          return 'background: rgb(234 215 79) ';
        }
      }


    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // console.log(this.$store.state.women_baby.uid)
    

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getUserList()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() {
}
}
</script>
<style scoped>
.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}
</style>