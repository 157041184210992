<template>
  <div class="container">
    <div class="pageTop">
      <span>心狗体检数据查询</span>
      <img src="../../../public/img/logo.png" alt="Logo" class="pageTop-logo" />
    </div>
    <el-divider></el-divider>

    <div class="input-section" v-if="result === null">
      <el-input
          class="input-field"
          placeholder="请输入用户姓名或手机号"
          v-model="userNameOrPhone"
          clearable
          prefix-icon="el-icon-search"
      ></el-input>
      <el-button type="primary" @click="fetchUid" class="query-button">查询</el-button>
    </div>

    <!-- 显示查询结果 -->
    <div v-if="result !== null" class="button-section">
<!--      <el-divider></el-divider>-->
      <div class="button-row">
        <el-button type="primary" @click="NewGetECG" class="secondary-button">查看心电记录</el-button>
        <el-button type="primary" @click="NewOtherFile" class="secondary-button">查看综合报告</el-button>
      </div>
      <p class="button-section-tip">微信用户请使用外部浏览器下载报告</p>
    </div>

    <div v-if="loading" class="loading-section">
<!--      <el-divider></el-divider>-->
      <p>查询中，请稍候...</p>
    </div>
    <div v-if="error" class="error-section">
<!--      <el-divider></el-divider>-->
      <p style="color: red;">查询失败，请检查输入或稍后再试。</p>
    </div>

    <div class="across-template-wrapper">
      <AcrossTemplate ref="GoAcross" />
    </div>
  </div>
</template>

<script>
import AcrossTemplate from "@/views/common/across_fourth.vue";
import Cookies from "js-cookie";

export default {
  components: {
    AcrossTemplate,
  },

  data() {
    return {
      userNameOrPhone: "", // 输入的用户名或手机号
      result: null, // 查询结果的状态
      loading: false, // 是否正在加载
      error: false, // 查询是否出错
    };
  },
  methods: {
    NewGetECG() {
      this.$refs.GoAcross.goECG();
    },
    NewOtherFile() {
      this.$refs.GoAcross.goOtherFile();
    },

    fetchUid() {
      if (!this.userNameOrPhone) {
        console.warn("请输入用户名或手机号！");
        return;
      }
      this.loading = true;
      this.error = false;

      this.$http({
        url: this.$http.adornUrl("/personal/user/app/getUid"),
        method: "post",
        params: this.$http.adornParams({
          userName: this.userNameOrPhone,
        }),
      })
          .then(({ data }) => {
            if (data && data.code === 0) {
              console.log("查询结果：", data);
              this.result = data;
              Cookies.set("PID", data.uid, { path: "/" });
            } else {
              console.error("查询失败，服务器错误");
              this.error = true;
            }
          })
          .catch((error) => {
            console.error("查询出错：", error);
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    adjustTemplateWidth() {
      const screenWidth = window.innerWidth;
      const template = this.$refs.GoAcross.$el; // 获取 AcrossTemplate DOM 节点
      if (screenWidth < 768) {
        template.style.width = `${screenWidth - 20}px`; // 动态设置宽度，留边距
      } else {
        template.style.width = "600px"; // 固定宽度
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.adjustTemplateWidth);
    this.adjustTemplateWidth();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.adjustTemplateWidth);
  },
};
</script>

<style scoped>
/* 通用容器样式 */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;

  /* 背景图片设置 */
  background-image: url('../../../public/img/ErWeiMaBack.png'); /* 确保路径正确 */
  background-size: cover; /* 背景图覆盖整个容器 */
  background-repeat: no-repeat; /* 不重复 */
  background-position: center; /* 图片居中显示 */
  min-height: 100vh; /* 让容器占满整个视窗高度 */
}


/* 页面标题 */
.pageTop {
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
  font-size: 24px; /* 确保文字大小合适 */
  margin-bottom: 5px;
}

.pageTop-logo {
  height: 1em; /* 图片高度与文字行高一致 */
  margin-left: 10px; /* 添加图片与文字的间距 */
  display: inline-block; /* 保持图片为行内块 */
}


.input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}

/* 输入框 */
.input-field {
  width: 90%;
  max-width: 500px;
  margin-bottom: 20px;
  font-size: 18px; /* 增大字体 */
}

/* 查询按钮 */
.query-button,
.secondary-button {
  flex: 1; /* 按钮占据相同宽度 */
  width: 90%;
  max-width: 500px; /* 和输入框一致 */
  font-size: 20px; /* 增大字体 */
  padding: 10px 0; /* 增加按钮高度 */
}

/* 操作按钮区域 */
.button-section {
  display: flex;
  flex-direction: column; /* 按钮和提示文字垂直排列 */
  align-items: center;
  gap: 10px;
}

.button-section .button-row {
  display: flex;
  justify-content: center; /* 按钮水平居中 */
  gap: 10px; /* 按钮间距 */
  width: 100%;
}

.button-section .button-row .secondary-button {
  width: 90%; /* 按输入栏宽度比例调整 */
  max-width: 500px; /* 限制最大宽度 */
  font-size: 20px;
}

.button-section-tip {
  font-size: 16px;
  text-align: center;
}




/* 加载提示区域 */
.loading-section,
.error-section {
  text-align: center;
}

/* AcrossTemplate 容器样式 */
.across-template-wrapper {
  display: block;
  max-width: 100%; /* 限制最大宽度 */
  overflow-x: auto; /* 启用水平滚动 */
  margin: 0 auto; /* 居中对齐 */
}

/* 页中页固定宽度 */
.ecg-record {
  min-width: 600px; /* 固定宽度 */
  margin: 0 auto; /* 居中对齐 */
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .pageTop {
    font-size: 18px;
  }

  .input-field,
  .query-button,
  .secondary-button {
    width: 100%;
  }
}
</style>
