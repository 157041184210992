<!--  -->
<template>
    <div class='historypage'>
        <div align="center">
            <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
                <div style="margin:45px 57px">
                    <el-row class="title">
                        <el-col :span="6">
                            <div class="blank"></div>
                        </el-col>
                        <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                                胎心仪检测报告
                            </span></el-col>
                        <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                                src="../../../../../public/img/logo_xingou.png" /></el-col>
                    </el-row>
                    <!-- 绘制双横线 -->
                    <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
                    <el-row class="info">
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                姓名：{{ realName }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                性别：{{ gender === 1 ? '男' : '女' }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                年龄：{{ age }}</span></el-col>
                        <el-col :span="9"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                时间：{{ time }} </span></el-col>
                    </el-row>

                    <div id="myChart" style="width: 100%;height:300px;"></div>
                    <div id="myPie" style="width: 100%;height:400px;"></div>


                </div>
                <!-- <canvas id="ecg" width="793.7px" height="1122.52px" style="border:black 1px solid">您的浏览器不支持canvas</canvas><br> -->

                <!-- 按钮触发弹出框 -->
                <el-button type="primary" @click="showDialog = true">查看评分标准</el-button>
                <el-button type="primary" plain @click="getPdf('#DomPdf')">下载报告</el-button>

                <!-- 弹出框内容 -->
                <el-dialog title="评分标准" :visible.sync="showDialog" width="40%">
                    <div v-for="(table, index) in tableData" :key="index" style="margin-bottom: 20px;">
                        <h3>
                            {{ table.title }}
                            <el-tooltip v-if="index === 0" class="item" effect="light" placement="right"
                                popper-class="tooltip-table">
                                <template #content>
                                    <h3>NST的结果判读及处理</h3>
                                    <el-table :data="nstTableData" style="width: 600px;" :class="'custom-table-border'">
                                        <el-table-column prop="parameter" label="参数" align="center"></el-table-column>
                                        <el-table-column prop="normal" label="正常NST" align="center"></el-table-column>
                                        <el-table-column prop="atypical" label="不典型NST"
                                            align="center"></el-table-column>
                                        <el-table-column prop="abnormal" label="异常NST" align="center"></el-table-column>
                                    </el-table>
                                </template>
                                <el-button type="primary">说明</el-button>
                            </el-tooltip>
                        </h3>

                        <el-table :data="table.data" style="width: 100%;" :class="'custom-table-border'">
                            <el-table-column prop="item" label="项目" align="center"></el-table-column>
                            <el-table-column prop="score0" label="0 分" align="center"></el-table-column>
                            <el-table-column prop="score1" label="1 分" align="center"></el-table-column>
                            <el-table-column prop="score2" label="2 分" align="center"></el-table-column>
                        </el-table>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="showDialog = false">关闭</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { runInThisContext } from 'vm';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            htmlTitle: 'PDF_FHR',
            time: '',
            heartRate: [],
            overRate: 0,
            lowRate: 0,
            normRate: 0,
            header: {},
            realName: '',
            gender: '',
            age: '',
            personalfhr: {},
            x: [],
            showDialog: false,
            tableData: [
                {
                    title: "NST 评分标准",
                    data: [
                        { item: "胎心率基线（bpm）", score0: "＜100,＞180", score1: "100～109,161～180", score2: "110～160" },
                        { item: "胎动时 FHR 上升时间（s）", score0: "0～9", score1: "10～14", score2: "＞14" },
                        { item: "胎动时 FHR 上升幅度（bpm）", score0: "0～9", score1: "10～14", score2: "＞14" },
                        { item: "变异幅度（bpm）", score0: "0～4", score1: "5～9,＞30", score2: "10～30" },
                        { item: "胎动（bpm）", score0: "无胎动", score1: "1～2", score2: "＞2" }
                    ]
                },
                {
                    title: "KREBS 评分标准",
                    data: [
                        { item: "胎心率基线（bpm）", score0: "＜100,＞180", score1: "100～109,161～180", score2: "110～160" },
                        { item: "变异幅度（bpm）", score0: "0～4", score1: "5～9,＞25", score2: "10～25" },
                        { item: "变异频率（cpm）", score0: "0～2", score1: "3～6", score2: "＞6" },
                        { item: "加速（次/20 分）", score0: "无加速", score1: "1～4", score2: "＞4" },
                        { item: "减速（次/20 分）", score0: "＞2", score1: "1～2", score2: "无减速" },
                        { item: "胎动（bpm）", score0: "无胎动", score1: "1～4", score2: "＞4" }
                    ]
                },
                {
                    title: "改良 Fischer 评分标准",
                    data: [
                        { item: "胎心率基线（bpm）", score0: "＜100,＞180", score1: "100～109,161～180", score2: "110～160" },
                        { item: "变异幅度（bpm）", score0: "0～4", score1: "5～9,＞30", score2: "10～30" },
                        { item: "变异频率（cpm）", score0: "0～1", score1: "2～6", score2: "＞6" },
                        { item: "加速（次/20 分）", score0: "无加速", score1: "1～4", score2: "＞4" },
                        { item: "减速（次/20 分）", score0: "晚减或重度变异", score1: "轻度变异", score2: "无减速" }
                    ]
                },
                {
                    title: "Fischer 评分标准",
                    data: [
                        { item: "胎心率基线（bpm）", score0: "＜100,＞180", score1: "100～109,161～180", score2: "110～160" },
                        { item: "变异幅度（bpm）", score0: "0～4", score1: "5～9,＞25", score2: "10～25" },
                        { item: "变异频率（cpm）", score0: "0～1", score1: "2～5", score2: "＞5" },
                        { item: "加速（次/20 分）", score0: "无加速", score1: "周期加速", score2: "非周期加速" },
                        { item: "减速（次/20 分）", score0: "晚减或重度变异", score1: "轻度变异", score2: "无减速" }
                    ]
                }
            ],
            nstTableData: [
                { parameter: "胎心率基线", normal: "110～160 次/分", atypical: "100～110 次/分；＞160 次/分，＜30 分钟", abnormal: "胎儿心动过缓＜100 次/分；胎儿心动过速＞160 次/分，超过 30 分钟" },
                { parameter: "基线变异", normal: "6～25 次/分（中度变异）；≤5 次/分，持续＜40 分钟", atypical: "≤5 次/分，持续 40～80 分钟内", abnormal: "≤5 次/分，持续≥80 分钟；≥25 次/分，持续＞10 分钟" },
                { parameter: "减速", normal: "无减速或偶发变异减速，持续＜30 秒", atypical: "变异减速，持续 30～60 秒内", abnormal: "变异减速，持续时间≥60 秒；晚期减速" },
                { parameter: "加速（≥32 周）", normal: "40 分钟内 2 次或 2 次以上加速超过 15 次/分，持续 15 秒", atypical: "40～80 分钟内 2 次以下加速超过 15 次/分，持续 15 秒", abnormal: "大于 80 分钟 2 次以下加速超过 15 次/分，持续 15 秒" },
                { parameter: "加速（＜32 周）", normal: "40 分钟内 2 次或 2 次以上加速超过 10 次/分，持续 10 秒", atypical: "40～80 分钟内 2 次以下加速超过 10 次/分，持续 10 秒", abnormal: "大于 80 分钟 2 次以下加速超过 10 次/分，持续 10 秒" }
            ],
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        initECGChart(domName) {
            this.myChart = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        initPieChart(domName) {
            this.myPie = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        Babychart() {
            this.option = {
                xAxis: {
                    type: 'value',
                    name: '时间(s)'
                },
                yAxis: {
                    type: 'value',
                    name: '心率(次/min)',
                    max: 200,

                },
                dataset: [
                    {
                        source: [
                            this.x,
                            this.heartRate
                        ]
                    },
                ],
                series: [
                    {
                        datasetIndex: 0,
                        symbol: "none" /*去掉小圆点*/,
                        // name: '心率',
                        type: "line",
                        seriesLayoutBy: "row",
                        markLine: {
                            silent: true, // 不响应鼠标事件
                            lineStyle: {
                                type: 'dashed' // 设置为虚线
                            },
                            data: [
                                { yAxis: 110 }, // 警戒线1
                                { yAxis: 160 }  // 警戒线2
                            ],
                            // 颜色设置-->
                            lineStyle: {color: 'red'}

                        }
                    },
                ],
                legend: {

                }
            };
            this.myChart.setOption(this.option);
        },
        BabyPie() {
            this.Pieoption = {
                title: {
                    text: '诊断结论汇总',
                    subtext: '仅供参考',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                series: [
                    {
                        name: '胎心诊断结论',
                        type: 'pie',
                        radius: ['20%', '60%'],
                        center: ['50%', '60%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            normal: {
                                color: function (colors) {
                                    var colorList = [
                                        '#F4D03F',
                                        '#E74C3C',
                                        '#91cd77',
                                    ];
                                    return colorList[colors.dataIndex];
                                },
                                borderRadius: 5,
                                borderColor: '#fff',
                                borderWidth: 2
                            },

                        },
                        data: [
                            { value: this.overRate, name: '心率过速' },
                            { value: this.lowRate, name: '心率过缓' },
                            { value: this.normRate, name: '心率正常' },
                        ]
                    }
                ]
            };
            this.myPie.setOption(this.Pieoption);
        },
        getinfo() {
            this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            this.$http.adornHeader(this.header)
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                // headers: this.$http.adornHeader(this.header), 
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.gender = data.gender;
                    this.age = data.age;
                    this.realName = data.realName
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        processHeartRate() {
            console.log("心率数据:", this.heartRate);
            this.x = [];
            this.overRate = 0;
            this.lowRate = 0;
            this.normRate = 0;

            for (let i = 0; i < this.heartRate.length; i++) {
                this.x.push(i);
                if (this.heartRate[i] > 160) {
                    this.overRate++;
                } else if (this.heartRate[i] < 110) {
                    this.lowRate++;
                } else {
                    this.normRate++;
                }
            }

            console.log("x:", this.x);
            console.log("高于160次数:", this.overRate);
            console.log("低于110次数:", this.lowRate);
            console.log("正常次数:", this.normRate);
        },
        initCharts() {
            console.log("初始化图表...");
            this.initECGChart(document.getElementById("myChart"));
            this.initPieChart(document.getElementById("myPie"));
            this.Babychart();
            this.BabyPie();
            this.getinfo();
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // console.log('看看query');
        // console.log(this.$route.query);
        // this.uid = this.$route.query.uid;

        // if (this.$route.query.fhrdate === undefined) {
        //     // 异步请求获取数据
        //     this.$http({
        //         url: this.$http.adornUrl("/personal/personalfhr/app/getRecentRecord"),
        //         method: "post",
        //         params: this.$http.adornParams({ uid: this.uid }),
        //     }).then(({ data }) => {
        //         if (data && data.code === 0) {
        //             console.log("看看数据");
        //             console.log(data);
        //             this.personalfhr = data.personalFhrEntities;

        //             this.time = this.personalfhr.fhrdate;
        //             var conclusion = this.personalfhr.hbvalue.split(',');

        //             this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
        //                 return String(item) !== '0';
        //             });

        //             // 异步完成后，调用处理逻辑
        //             this.processHeartRate();
        //             // 数据准备好后初始化图表
        //         } else {
        //             console.log("看看报错");
        //             this.$message.error(data.msg);
        //         }
        //     });
        // } else {
        //     // 同步处理URL参数
        //     this.time = this.$route.query.fhrdate;
        //     var conclusion = this.$route.query.hbvalue.split(',');
        //     this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
        //         return String(item) !== '0';
        //     });

        //     console.log("心率数据:", this.heartRate);

        //     // 同步完成后，调用处理逻辑
        //     this.processHeartRate();
        //     // 数据准备好后初始化图表
        // }

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        console.log('看看query');
        console.log(this.$route.query);
        this.uid = this.$route.query.uid;

        if (this.$route.query.fhrdate === undefined) {
            // 异步请求获取数据
            this.$http({
                url: this.$http.adornUrl("/personal/personalfhr/app/getRecentRecord"),
                method: "post",
                params: this.$http.adornParams({ uid: this.uid }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data);
                    this.personalfhr = data.personalFhrEntities;

                    this.time = this.personalfhr.fhrdate;
                    var conclusion = this.personalfhr.hbvalue.split(',');

                    this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
                        return String(item) !== '0';
                    });

                    // 异步完成后，调用处理逻辑
                    this.processHeartRate();
                    // 数据准备好后初始化图表
                    this.initCharts();

                } else {
                    console.log("看看报错");
                    this.$message.error(data.msg);
                }
            });
        } else {
            // 同步处理URL参数
            this.time = this.$route.query.fhrdate;
            var conclusion = this.$route.query.hbvalue.split(',');
            this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
                return String(item) !== '0';
            });

            console.log("心率数据:", this.heartRate);

            // 同步完成后，调用处理逻辑
            this.processHeartRate();
            // 数据准备好后初始化图表
            this.initCharts();

        }

        // this.initECGChart(document.getElementById("myChart"));
        // this.initPieChart(document.getElementById("myPie"));
        // this.Babychart()
        // this.BabyPie()
        // this.getinfo()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}
/* .custom-table-border >>> .el-table__body,
.custom-table-border >>> .el-table__header, */
.custom-table-border >>> .el-table__row,
.custom-table-border >>> td,
.custom-table-border >>> th {
    border: 1px solid black !important; /* 设置边框颜色为黑色 */
}
</style>